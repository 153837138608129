import React, { useState, useEffect } from 'react'
import axios from 'axios'
// import { navigate } from 'gatsby-link'
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl'
// import Layout from '../../layouts'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import Avatar from '../../components/Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextAnimation from 'react-animate-text'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'

// function encode(data) {
//   return Object.keys(data)
//     .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
//     .join('&')
// }

const ContactForm = () => {
  const [formValues, setFormValues] = useState({ email: '', name: '', message: '' })
  const [showNameField, setShowNameField] = useState(false)
  const [showGreeting, setShowGreeting] = useState(false)
  const [showTextArea, setShowTextArea] = useState(true)
  const [showEmailInfo, setShowEmailInfo] = useState(false)
  const [showEmailField, setShowEmailField] = useState(true)
  const [showSendButton, setShowSendButton] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [sending, setSending] = useState(false)
  const intl = useIntl()

 //  componentDidMount() {
 //    this.NameField = setTimeout(
 //            () => this.setState({
 //              showNameField: true,
 //            }),
 //            3500,
 //        );
 // }

   useEffect(() => {
     setTimeout(function () {
      setShowNameField(true)
     }, 3500)
   })


   const welcomeTxt = intl.formatMessage({ id: 'contactWelcomeTxt' })
   const welcomeRes1P1 = intl.formatMessage({ id: 'contactWelcomeRes1P1' })
   const welcomeRes1P2 = intl.formatMessage({ id: 'contactWelcomeRes1P2' })
   const welcomeReq1 = intl.formatMessage({ id: 'contactWelcomeReq1' })

   function handleInputChange (e) {
     e.persist()
     setFormValues(currentValues => ({
       ...currentValues,
       [e.target.name]: e.target.value
     }))
   }


  function handleSubmit (e) {
    e.preventDefault()

    const to = 'office@digital-comfort-zone.com'

    const message = formValues.message
    const name = formValues.name
    const email = formValues.email

    setSending(true)

   //const datetimeplace = date + ' ' + 'um' + ' ' + time + ' ' + 'in' + ' ' + place + '.'
   //const name = firstname + ' ' + secondname + '!'
   //const to = emailSender

   axios.post("/.netlify/functions/contact-form", {
     name,
     // customercompanyname,
     email,
     message,
     to
     // logo
   }).then((response) => {

     if (response.status === 200) {
       setSending(false)
       setSuccess(true)
     }

     // console.log('success', response)
     return {
       statusCode: 200,
       body: JSON.stringify(response)
     }
   }).catch((error) => {
     // console.log('error', error)
     setSending(false)
     setError(true)
     return {
       statusCode: 400,
       body: JSON.stringify(error)
     }
   //  console.log('...dont know')
   })
  }


   function renderSuccess () {
     setTimeout(() =>
       setSuccess(false), 15000)
     if (success === true) {
       return (
         <Success>
           <FormattedMessage id='successSendMessage' />{' '}<FontAwesomeIcon icon={faCheck} />
         </Success>
       )
     }
   }

   function renderSendingTime () {
     if (sending === true) {
       return (
         <Info>
           <FormattedMessage id='sendingMessage' />
         </Info>
       )
     }
   }

   function renderError () {
    setTimeout(() =>
      setError(false), 15000)
    if (error === true) {
      return (
        <Error>
          <FormattedMessage id='errorSendMessage' />{' '}<FontAwesomeIcon icon={faTimes} />
        </Error>
      )
    }
  }

  return (
    <div className="columns is-multiline is-centered">
      <Panel className='column is-6 is-one-third-widescreen'>
        <div className='column is-12 has-text-centered'>
          <Avatar />
        </div>
        <form
          name="contact-test"
          method="post"
          // action="/kontakt/danke/"

          onSubmit={handleSubmit}
        >

          <div className="field">
            <label className="label" htmlFor={'name'}>
              <TextAnimation charInterval='80'>
                <span style={{ color: 'white' }}>
                  {welcomeTxt}
                </span>
              </TextAnimation>
            </label>

            <Fade collapse when={showNameField}>
              <div className="control">
                <input
                  className="input is-primary"
                  type={'text'}
                  name={'name'}
                  onChange={handleInputChange}
                  id={'name'}
                  required={true}
                  style={{ borderRadius: '0px' }}
                  value={formValues.name}
                />
              </div>
            </Fade>
          </div>

          <div className="field">
            <Fade collapse when={formValues.name.length >= 3}>
              <label className="label" htmlFor={'message'} style={{ marginBottom: '10px' }}>

                <span style={{ color: 'white' }}>
                  {welcomeRes1P1} {formValues.name}! {welcomeRes1P2}
                </span>

              </label>
              <div className="control" style={{ paddingTop: '1.5rem' }}>
                <textarea
                  className="textarea has-fixed-size is-primary"
                  name={'message'}
                  onChange={handleInputChange}
                  id={'message'}
                  required={true}
                  style={{ borderRadius: '0px' }}
                  value={formValues.message}
                />
              </div>
            </Fade>

          </div>
          <div className="field">
            <Fade collapse when={formValues.message.length >= 4}>
              <label className="label" htmlFor={'email'} style={{ marginBottom: '10px' }}>
                <span style={{ color: 'white' }}>
                  {welcomeReq1}
                </span>

              </label>
              <div className="control">
                <input
                  className="input is-primary"
                  type={'email'}
                  name={'email'}
                  onChange={handleInputChange}
                  id={'email'}
                  required={true}
                  style={{ borderRadius: '0px' }}
                  value={formValues.email}
                />
              </div>
            </Fade>

          </div>
          <Fade when={formValues.email.length >= 5}>
            <div className="field">
              <button className="button is-medium is-outlined is-primary is-inverted" type="submit" style={{ marginTop: '15px', marginBottom: '15px' }}>
                <FormattedMessage id='contactFormButtonText' />&nbsp;<FontAwesomeIcon icon={faPaperPlane} />
              </button>
              {renderSendingTime()}
              {renderSuccess()}
              {renderError()}
            </div>
          </Fade>
        </form>
      </Panel>
    </div>
  )
}

export default ContactForm

const Panel = styled.div`
  border-radius: 3px;
  background: -moz-linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.7) 100%);
  background: -webkit-linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.7) 100%);
  background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.7) 100%);
  box-shadow: 0 0 15px rgba(0,0,0,.6);
  padding: 32px;
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const Success = styled.div`
  margin: 0 auto;
  width: 250px;
  text-align: center;
  background: hsl(141, 71%, 48%);
  color: #fff;
  padding: 10px;
`

const Info = styled.div`
  margin: 0 auto;
  width: 250px;
  text-align: center;
  background: hsl(212, 57%, 50%);
  color: #fff;
  padding: 10px;
`

const Error = styled.div`
  margin: 1rem auto;
  width: 250px;
  text-align: center;
  background: hsl(348, 100%, 61%);
  color: #fff;
  padding: 10px;
`
