import React, { useState, useEffect } from 'react'

const defaultState = {
  cartItems: () => ([])
}

export const CartItemsContext = React.createContext(defaultState)

export const CartItemsProvider = (props) => {
  const [cartItems, setCartItems] = useState([])

  useEffect(() => {
    if(typeof window !== 'undefined'){
      if (localStorage.getItem("click&collect-cart") !== null) {
        let localCart = localStorage.getItem("click&collect-cart");
        setCartItems(JSON.parse(localCart))
      }else{
        localStorage.setItem("click&collect-cart", JSON.stringify(cartItems))
      }
    }
  }, [props])

  return (
    <CartItemsContext.Provider value={[cartItems, setCartItems]}>
      {props.children}
    </CartItemsContext.Provider>
  )
}

// export default CartItemsContext
