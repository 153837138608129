import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const Avatar = () => (
  <AvatarWrapper>
    <StaticImage
      src='../img/logo.png'
      alt='click-collect-screen'
      placeholder='blurred'
      layout='constrained'
      objectFit='cover'
      objectPosition='50% 50%'
      style={{ marginTop: '.5rem', width: '60px', height: '80px' }}
    />
  </AvatarWrapper>
)

export default Avatar

const AvatarWrapper = styled.div`
  border-radius: 100%;
  width: 100px;
  height: 100px;
  background: #fff;
  margin: 0 auto;
`
