import React from 'react'

export const DynamicBrowserTabTitleTemplate = ({ data }) => {
  const { tab } = data

  if (typeof window !== 'undefined') {
    const getBrowserHiddenProps = () => {
      let hidden, visibilityChange
      if (typeof document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }

      return {
        hidden,
        visibilityChange
      }
    }

    const { hidden, visibilityChange } = getBrowserHiddenProps()

    if (typeof document.addEventListener !== "undefined" && typeof hidden !== "undefined") {
      document.addEventListener(visibilityChange, () => {
        if (document[hidden]) {
          document.title = tab.inactive
        } else {
          document.title = tab.active
        }
      }, false)
    }
  }

  return (
    <React.Fragment>
    </React.Fragment>
  )
}

const DynamicBrowserTabTitle = props => {
  if (!props.data) {
    return null
  }
  const data = props.data.edges[0].node.frontmatter
  return <DynamicBrowserTabTitleTemplate data={data} />
}

export { DynamicBrowserTabTitle }
