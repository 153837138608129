import React from 'react'
import { navigate } from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const NetlifyExpressContactForm = class extends React.Component {
    constructor(props) {
      super(props)
      this.state = { isValidated: false }
    }

    handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = e => {
      e.preventDefault()
      const form = e.target
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.state,
        }),
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch(error => alert(error))
    }

  render () {
    return (
        <div>
              <form
                name="fast-contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
              <input type="hidden" name="form-name" value="fast-contact" />
              <div hidden>
                <label>
                  Don’t fill this out:{' '}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </div>

              {/*
                <label className='label' style={{ color: '#fff', textAlign: 'center' }} for="email">
                  Bleib am Laufenden mit unserem Newsletter
                </label>
              */}

              <div class="field has-addons has-addons-centered">
                <div class="control is-expanded">

                  <input
                    className="input is-medium is-primary" style={{ borderRadius: '0px' }}
                    type={'email'}
                    name={'email'}
                    onChange={this.handleChange}
                    id={'email'}
                    required={true}
                    placeholder={'email@address.com'}
                  />
                </div>
                <div class="control">
                  <button className="button is-medium is-primary" role='button' type="submit" aria-label="submit">
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
               </div>
             </div>
            </form>
          </div>
    )
  }
}

export default NetlifyExpressContactForm
