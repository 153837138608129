import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
// import styled from 'styled-components'
import { StaticQuery, graphql, Link } from 'gatsby'
import Headroom from 'react-headroom'
// import HeadroomScrollTop from 'react-headroom'
// import scrollTopIcon from '../img/scrollTop.svg'
import { Navbar } from '../components/Navbar'
import { Footer } from '../components/Footer'
// import { Banner } from '../components/Banner'
import { DynamicBrowserTabTitle } from '../components/DynamicBrowserTabTitle'
import NavbarSideRight from '../components/navigation/side/right/NavbarSide'
import NavbarBottom from '../components/navigation/bottom/NavbarBottom'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import '../components/all.sass'
import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

// const IOSWrap = styled.div`
//   padding-top: env(safe-area-inset-top);
//   padding-left: env(safe-area-inset-left);
//   padding-right: env(safe-area-inset-right);
//   padding-bottom: env(safe-area-inset-bottom);
// `

// const Wrapper = styled.div`
//   margin: 0 auto;
//   max-width: 960px;
//   padding: 0px 1.0875rem 1.45rem;
// `

// const Layout = ({ navbarData = null, footerData = null, bannerData = null, browserTab = null, children }) => {
const Layout = ({ navbarData = null, footerData = null, browserTab = null, children }) => {
  return (

    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => (
        <>
          {/*
          <NavigationShop siteTitle={data.site.siteMetadata.title} />
          */}

          <DynamicBrowserTabTitle data={browserTab} />

          <Helmet>
            <html lang='de' />
            <title>{data.site.siteMetadata.title}</title>

            <meta
              name='description'
              content={data.site.siteMetadata.description}
            />

            <meta
              name='viewport' content='width=device-width, viewport-fit=cover'
            />

            <meta name='apple-mobile-web-app-capable' content='yes' />

            {/* PORTRAIT MODE */}
            <link href='/img/apple_splash_640.png' sizes='640x1136' rel='apple-touch-startup-image' media='(device-width:  320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
            <link href='/img/apple_splash_750.png' sizes='750x1334' rel='apple-touch-startup-image' media='(device-width:  375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
            <link href='/img/apple_splash_828.png' sizes='828x1792' rel='apple-touch-startup-image' media='(device-width:  414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
            <link href='/img/apple_splash_1125.png' sizes='1125x2436' rel='apple-touch-startup-image' media='(device-width:  375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' />
            <link href='/img/apple_splash_1242.png' sizes='1242x2208' rel='apple-touch-startup-image' media='(device-width:  414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' />
            <link href='/img/apple_splash_1242x2688.png' sizes='1242x2688' rel='apple-touch-startup-image' media='(device-width:  414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' />
            <link href='/img/apple_splash_1536.png' sizes='1536x2048' rel='apple-touch-startup-image' media='(device-width:  768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
            <link href='/img/apple_splash_1668.png' sizes='1668x2224' rel='apple-touch-startup-image' media='(device-width:  834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
            <link href='/img/apple_splash_1668x2388.png' sizes='1668x2388' rel='apple-touch-startup-image' media='(device-width:  834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
            <link href='/img/apple_splash_2048.png' sizes='2048x2732' rel='apple-touch-startup-image' media='(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
            <link href='/img/apple_splash_2532.png' sizes='1170x2532' rel='apple-touch-startup-image' media='(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' />
            <link href='/img/apple_splash_1334.png' sizes='750x1334' rel='apple-touch-startup-image' media='(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
            <link href='/img/apple_splash_2160.png' sizes='1620x2160' rel='apple-touch-startup-image' media='(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
            <link href='/img/apple_splash_2360.png' sizes='1640x2360' rel='apple-touch-startup-image' media='(device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' />
            <link href='/img/apple_splash_2340.png' sizes='1080x2340' rel='apple-touch-startup-image' media='(device-width: 360px) and (device-height: 780px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' />
            <link href='/img/apple_splash_2778.png' sizes='1284x2778' rel='apple-touch-startup-image' media='(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' />

            {/* LANDSCAPE MODE */}
            <link rel='apple-touch-startup-image' href='/img/apple_splash_640_landscape.png' media='(device-width:  320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_750_landscape.png' media='(device-width:  375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_828_landscape.png' media='(device-width:  414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_1125_landscape.png' media='(device-width:  375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_1242_landscape.png' media='(device-width:  414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_1242x2688_landscape.png' media='(device-width:  414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_1536_landscape.png' media='(device-width:  768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_1668_landscape.png' media='(device-width:  834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_1668x2388_landscape.png' media='(device-width:  834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_2048_landscape.png' media='(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_2532_landscape.png' media='(device-width: 844px) and (device-height: 390px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_1334_landscape.png' media='(device-width: 667px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_2160_landscape.png' media='(device-width: 1080px) and (device-height: 810px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_2360_landscape.png' media='(device-width: 1180px) and (device-height: 820px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_2340_landscape.png' media='(device-width: 780px) and (device-height: 360px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)' />
            <link rel='apple-touch-startup-image' href='/img/apple_splash_2778_landscape.png' media='(device-width: 926px) and (device-height: 428px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)' />

            <link
              rel='apple-touch-icon'
              sizes='180x180'
              href='/img/apple-touch-icon.png'
            />
            <link
              rel='icon'
              type='image/png'
              href='/img/favicon-32x32.png'
              sizes='32x32'
            />
            <link
              rel='icon'
              type='image/png'
              href='/img/favicon-16x16.png'
              sizes='16x16'
            />

            <link
              rel='mask-icon'
              href='/img/safari-pinned-tab.svg'
            />
            {/*
            <meta name='theme-color' content='#fff' />
            */}
            <meta property='og:type' content='business.business' />
            <meta property='og:title' content={data.site.siteMetadata.title} />
            <meta property='og:url' content='/' />
            {/* <meta property='og:image' content='/img/og-image.jpg' />
            */}
          </Helmet>

          <CookieConsent
            enableDeclineButton
            location='top'
            buttonText='OK'
            declineButtonText='Ablehnen'
            cookieName='click-collect'
            style={{ background: '#000000', zIndex: '200000', padding: '1rem' }}
            buttonStyle={{ color: 'white', backgroundColor: '#DF8136', fontSize: '13px' }}
            declineButtonStyle={{ color: '#fff', backgroundColor: '#DF8136', fontSize: '13px' }}
            expires={150}
            onAccept={() => {
              Cookies.set('click-collect', true)
            }}
            onDecline={() => {
              Cookies.set('click-collect', false)
              // Cookies.remove('click-collect')
            }}
          >
            <span role='img' aria-label='Cookie'>🍪{' '}</span>
            Wir nutzen Cookies um die Benutzung zu verbessern!
            <span role='img' aria-label='Cookie'>{' '}🍪🍪🍪{' '}</span>
            <Link to='/datenschutz' className='has-text-primary'>Datenschutz</Link>
          </CookieConsent>

          {/*<Banner data={bannerData} />*/}

          <Headroom
            style={{
              zIndex: 2000,
              boxShadow: '1px 1px 1px rgba(0,0,0,0.25)'
            }}
          >

            <Navbar data={navbarData} />
            <div id='top' />
          </Headroom>


          {children}

          <Footer data={footerData} />
          <NavbarSideRight />
          {/*
            <HeadroomScrollTop pinStart={1000} style={{ top: 'calc(80vh - 0px)', right: 'auto' }}>
            <a href='#top'><img src={scrollTopIcon} alt='Back to top' style={{ width: '3rem', height: '3rem', marginLeft: '1rem', float: 'left', marginBottom: '1rem' }} /></a>
          </HeadroomScrollTop>
          */}
          <NavbarBottom />
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export const query = graphql`
  fragment LayoutFragment on Query {
    navbarData: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "navbar" } } }) {
      edges {
        node {
          id
          frontmatter {
            bannerinfo
            menuItems {
              label
              linkType
              linkURL
            }
          }
        }
      }
    }
    footerData: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "footer" } } }) {
      edges {
        node {
          id
          frontmatter {
            mainpitch {
              logo {
                childImageSharp {
                  gatsbyImageData(
                    width: 150
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
              title
              subtitle
              copyright
            }
          }
        }
      }
    }
    bannerData: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "banner" } } }) {
      edges {
        node {
          id
          frontmatter {
            info {
              bannerinfo
            }
          }
        }
      }
    }
    browserTab: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "browsertab" } } }) {
      edges {
        node {
          id
          frontmatter {
            tab {
              inactive
              active
            }
          }
        }
      }
    }
  }
`

export default Layout
