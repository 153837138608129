import React, { Component } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import NewsletterSignUp from '../NetlifyExpressContactForm'
import styled from 'styled-components'
import facebook from '../../img/social/logo-facebook.svg'
import instagram from '../../img/social/logo-instagram.svg'
import youtube from '../../img/social/logo-youtube.svg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import logoFont from '../../img/logo-font.svg'
// import useInYourBrowser from '../../img/use_in_your_browser.svg'
import { FormattedMessage } from 'gatsby-plugin-react-intl'

{/*
export const FooterTemplate = ({ data }) => {
*/}

export class FooterTemplate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showDetail: false,
      id: null,
      showAboutLinks: false,
      showExploreLinks: false,
      showHelpLinks: false,
      showPrivacyLinks: false,
    }
  }

  showAboutLinks = (e) => {
    e.preventDefault()
    this.setState({
      showAboutLinks: !this.state.showAboutLinks
    });
  }

  showExploreLinks = (e) => {
    e.preventDefault()
    this.setState({
      showExploreLinks: !this.state.showExploreLinks
    });
  }

  showHelpLinks = (e) => {
    e.preventDefault()
    this.setState({
      showHelpLinks: !this.state.showHelpLinks
    });
  }

  showPrivacyLinks = (e) => {
    e.preventDefault()
    this.setState({
      showPrivacyLinks: !this.state.showPrivacyLinks
    });
  }

  render () {
  const { data } = this.props
  const { mainpitch } = data

  const logoFooter = getImage(mainpitch.logo)

  return (
    <div style={{ position: 'relative' }}>
      <footer className='footer'>


        <div style={{ maxWidth: '150px', margin: '5rem auto', textAlign: 'center' }}>
          {/* <Img fixed={mainpitch.logo.childImageSharp.fixed} alt='Logo' style={{ width: '150px' }} /> */}

          <GatsbyImage
            image={logoFooter}
            alt='Logo'
            // width={280}
            // layout='constrained'
            imgStyle={{ margin: '0 auto' }}
            // objectPosition='50% 50%'
            loading='eager'
          />
        </div>

        {/*<DesktopLinks>
          <div className='columns is-mobile is-centered is-multiline is-paddingless'>

              <div className='column is-3'>
                <div style={{ width: '100px', textAlign: 'left', margin: '0 auto' }}>
                  <h4 className='subtitle is-6 has-text-weight-medium has-text-white-ter' style={{ margin: '0px' }}>
                    Über uns
                  </h4>
                  <Link to='/' className='has-text-primary'>Unternehmen</Link>
                  <br />
                  <Link to='/' className='has-text-primary'>Team</Link>
                  <br />
                  <Link to='/' className='has-text-primary'>Nachhaltigkeit</Link>
                  <br />
                  <Link to='/' className='has-text-primary'>Jobs</Link>
                </div>
              </div>

              <div className='column is-3'>
                <div style={{ width: '100px', textAlign: 'left', margin: '0 auto' }}>
                  <h4 className='subtitle is-6 has-text-weight-medium has-text-white-ter' style={{ margin: '0px' }}>
                    Entdecken
                  </h4>
                  <Link to='/' className='has-text-primary'>Stories</Link>
                  <br />
                  <Link to='/' className='has-text-primary'>Veranstaltungen</Link>
                  <br />
                  <Link to='/karte' className='has-text-primary'>Barkarte</Link>
                  <br />
                  <Link to='/' className='has-text-primary'>Tischreservierung</Link>
                </div>
              </div>

              <div className='column is-3'>
                <div style={{ width: '100px', textAlign: 'left', margin: '0 auto' }}>
                  <h4 className='subtitle is-6 has-text-weight-medium has-text-white-ter' style={{ margin: '0px', whiteSpace: 'nowrap' }}>
                    Allgemeines
                  </h4>
                  <Link to='/' className='has-text-primary'>Öffnungszeiten</Link>
                  <br />
                  <Link to='/' className='has-text-primary'>Kontaktadresse</Link>
                  <br />
                  <Link to='/' className='has-text-primary'>Email</Link>
                  <br />
                  <Link to='/' className='has-text-primary'>Telefon</Link>
                  <br />
                  <Link to='/' className='has-text-primary'>Social</Link>
                  <br />
                </div>
              </div>

              <div className='column is-3'>
                <div style={{ width: '100px', textAlign: 'left', margin: '0 auto' }}>
                  <h4 className='subtitle is-6 has-text-weight-medium has-text-white-ter' style={{ margin: '0px' }}>
                    Rechtliches
                  </h4>
                  <Link to='/' className='has-text-primary'>AGB</Link>
                  <br />
                  <Link to='/' className='has-text-primary'>Impressum</Link>
                  <br />
                  <Link to='/' className='has-text-primary'>Datenschutz</Link>
                </div>
              </div>

          </div>


        </DesktopLinks>

        <MobileExpander onClick={this.showAboutLinks}>
          <h4 className='subtitle is-6 has-text-weight-medium has-text-white-ter' style={{ display: 'inline-block', margin: '0px' }}>
            Über uns
          </h4>
          {this.state.showAboutLinks ? <FontAwesomeIcon onClick={this.showAboutLinks} icon='minus' size='1x' className='has-text-primary is-pulled-right' /> : <FontAwesomeIcon onClick={this.showAboutLinks} icon='plus' size='1x' className='has-text-primary is-pulled-right' /> }

          <Fade collapse when={this.state.showAboutLinks}>
            <Link to='/' className='has-text-primary'>Unternehmen</Link>
            <Link to='/' className='has-text-primary'>Team</Link>
            <Link to='/' className='has-text-primary'>Jobs</Link>
          </Fade>
        </MobileExpander>

        <MobileExpander onClick={this.showExploreLinks}>
          <h4 className='subtitle is-6 has-text-weight-medium has-text-white-ter' style={{ display: 'inline-block', margin: '0px' }}>
            Entdecken
          </h4>
          {this.state.showExploreLinks ? <FontAwesomeIcon onClick={this.showExploreLinks} icon='minus' className='has-text-primary is-pulled-right' /> : <FontAwesomeIcon onClick={this.showExploreLinks}
          icon='plus' size='1x' className='has-text-primary is-pulled-right' /> }

          <Fade collapse when={this.state.showExploreLinks}>
            <Link to='/' className='has-text-primary'>Stories</Link>
            <Link to='/' className='has-text-primary'>Veranstaltungen</Link>
            <Link to='/karte' className='has-text-primary'>Barkarte</Link>
            <Link to='/' className='has-text-primary'>Tischreservierung</Link>
          </Fade>
        </MobileExpander>

        <MobileExpander onClick={this.showHelpLinks}>
          <h4 className='subtitle is-6 has-text-weight-medium has-text-white-ter' style={{ display: 'inline-block', margin: '0px' }}>
            Kontakt
          </h4>
          {this.state.showHelpLinks ? <FontAwesomeIcon onClick={this.showHelpLinks} icon='minus' size='1x' className='has-text-primary is-pulled-right' /> : <FontAwesomeIcon onClick={this.showHelpLinks}
          icon='plus' size='1x' className='has-text-primary is-pulled-right' /> }

          <Fade collapse when={this.state.showHelpLinks}>
            <Link to='/' className='has-text-primary'>FAQ</Link>
            <Link to='/' className='has-text-primary'>Kontaktadresse</Link>
            <Link to='/' className='has-text-primary'>Email</Link>
            <Link to='/' className='has-text-primary'>Telefon</Link>
            <Link to='/' className='has-text-primary'>Social</Link>
            <Link to='/' className='has-text-primary'>Öffnungszeiten</Link>
          </Fade>
        </MobileExpander>

        <MobileExpander onClick={this.showPrivacyLinks}>
          <h4 className='subtitle is-6 has-text-weight-medium has-text-white-ter' style={{ display: 'inline-block', margin: '0px' }}>
            Rechtliches
          </h4>
          {this.state.showPrivacyLinks ? <FontAwesomeIcon onClick={this.showPrivacyLinks}
          icon='minus' size='1x' className='has-text-primary is-pulled-right' /> : <FontAwesomeIcon onClick={this.showPrivacyLinks} icon='plus' size='1x' className='has-text-primary is-pulled-right' /> }

          <Fade collapse when={this.state.showPrivacyLinks}>
            <Link to='/' className='has-text-primary'>AGB</Link>
            <Link to='/' className='has-text-primary'>Impressum</Link>
            <Link to='/' className='has-text-primary'>Datenschutz</Link>
          </Fade>
        </MobileExpander>*/}
        {/*<NewsletterWrapper>
          <NewsletterSignUp />
        </NewsletterWrapper>*/}

        <div style={{ margin: '2rem auto' }}>
          {/*<img src={logoFont} alt="Logo Font" style={{ maxWidth: '200px', margin: '.5rem auto', display: 'block' }} />*/}
          {/*<h1 className='title has-text-centered has-text-white-ter' style={{ letterSpacing: '3px', marginTop: '2rem' }}>{mainpitch.title}</h1>*/}
          <h2
            className='subtitle has-text-centered has-text-white-ter'
          >
            <FormattedMessage id='headerText' />
            {/*{mainpitch.subtitle}*/}
          </h2>
        </div>

        {/*<div className='columns is-multiline'>*/}
          {/*<div className='column is-12 has-text-centered'>
            <img
              src={useInYourBrowser}
              alt='Browser Use'
              style={{ width: '12rem' }}
            />
          </div>*/}

          {/*<div className='column' style={{ background: 'background: #000000' }}>
            <div className='column get-in-touch-social-footer has-text-centered'>
              <a href='https://www.youtube.com/'>
                <img
                  className='social-icon'
                  src={youtube}
                  alt='Youtube Link'
                />
              </a>

              <a href='https://www.facebook.com/'>
                <img
                  className='social-icon'
                  src={facebook}
                  alt='Facebook Link'
                />
              </a>

              <a href='https://www.instagram.com/'>
                <img
                  className='social-icon'
                  src={instagram}
                  alt='Instagram Link'
                />
              </a>
            </div>
          </div>*/}

          <div className='column'>
            <div className='has-text-centered'>
              <p style={{ color: '#fff', opacity: '0.8' }}>©{' '}{mainpitch.copyright}{' '}{' '}<a className='has-text-white-ter' href='https://www.digital-comfort-zone.com' rel='noopener noreferrer' >digital-comfort-zone</a>{' '}{new Date().getFullYear()}</p>
            </div>
          </div>
        {/*</div>*/}

      </footer>
    </div>
  )
  }
}

const Footer = props => {
  if (!props.data) {
    return null
  }
  const data = props.data.edges[0].node.frontmatter
  return <FooterTemplate data={data} />
}

export { Footer }

const DesktopLinks = styled.div`
  padding: 40px;
  display: none;
  @media(min-width: 779px){
    background: rgba(0,0,0,0.8);
    display: block;
    max-width: 1400px;
    margin: 4rem auto;
`

const MobileExpander = styled.div`
  display: none;
  @media(max-width: 779px){
    background: rgba(0,0,0,0.8);
    display: block;
    padding: 10px;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    overflow: hidden;
    &:hover{
      cursor: pointer;
    }
  }
`

const NewsletterWrapper = styled.div`
  margin: 3rem auto;
  max-width: 350px;
`
