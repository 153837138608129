import React, { useRef } from 'react'
import ContactForm from './kontakt'
import styled from 'styled-components'
import useOutsideClick from './hooks/useOutsideClick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'

const ContactModal = (props) => {
  // const { handleShowContactModal } = props
  // const { handleCloseContactModal} = props
  const ref = useRef()

  useOutsideClick(ref, () => {
    // setShowContactPanel(false)
    props.handleCloseContactModal()
  })

  return (
    props.showContactModal ?
      <>
      <div>
      {/* <div ref={ref}> */}
      <Panel>
        <Close onClick={props.handleCloseContactModal}>
          <FontAwesomeIcon icon={faTimes} className='has-text-primary' style={{ fontSize: '2rem', cursor: 'pointer' }} />
        </Close>

          <Content>
            <ContactForm />
          </Content>


      </Panel>
      </div>
      </>
    : null
  )
}

export default ContactModal


const Close = styled.span`
  position: absolute;
  z-index: 3000;
  right: 10px;
  top: 10px;
  font-size: 16px;
  cursor: pointer;
`

const Panel = styled.div`
  position: fixed;
  top: 20%;
  z-index: 20000;
  height: auto;
  left: 0;
  right: 0;
  padding: 20px;
  // display: block;
`

const IconWrapper = styled.div`
  position: relative;
  // display: inline-block;
`

const Content = styled.div`
  height: 400px;
  overflow-y: scroll;
  padding: 10px;
  overflow-x: hidden;
`

const Icon = styled.div`
  position: absolute;
  top: 50px;
  left: auto;
  &:hover{
    cursor: pointer;
  }
`
