import React, { Component } from "react"
// import { Link } from 'gatsby'
import CustomLink from "../CustomLink"
import { Link, FormattedMessage, injectIntl } from 'gatsby-plugin-react-intl'
// import { Banner } from '../components/Banner'
import styled from 'styled-components'
// import CartIcon from '../CartIcon'
// import WishlistIcon from '../WishlistIcon'
// import SearchIcon from '../SearchIcon'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Bounce from 'react-reveal/Bounce';

// const IOSWrap = styled.div`
//   padding-top: env(safe-area-inset-top);
//   padding-left: env(safe-area-inset-left);
//   padding-right: env(safe-area-inset-right);
//   padding-bottom: env(safe-area-inset-bottom);
// `

export class NavbarTemplate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      background: 'transparent',
      active: false,
      navBarActiveClass: '',
      showButton: false,
    }
  }

  listenScrollEvent = e => {
    if (window.scrollY > 30) {
      this.setState({
        background: '#2A2A2A',
        showButton: true
      })

    } else {
      this.setState({
        background: 'transparent',
        showButton: false
      })
    }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.listenScrollEvent)

    // // Get all 'navbar-burger' elements
    // const $navbarBurgers = Array.prototype.slice.call(
    //   document.querySelectorAll('.navbar-burger'),
    //   0
    // )
    // // Check if there are any navbar burgers
    // if ($navbarBurgers.length > 0) {
    //   // Add a click event on each of them
    //   $navbarBurgers.forEach(el => {
    //     el.addEventListener('click', () => {
    //       // Get the target from the 'data-target' attribute
    //       const target = el.dataset.target
    //       const $target = document.getElementById(target)
    //
    //       // Toggle the 'is-active' class on both the 'navbar-burger' and the 'navbar-menu'
    //       el.classList.toggle('is-active')
    //
    //       $target.classList.toggle('is-active')
    //       // target.classList.remove('is-active')
    //     })
    //   })
    // }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render () {
    const { data } = this.props

    return (
      <>
        <div className='navigation'>
          {/* <Banner>{data.bannerinfo}</Banner> */}
          <nav
            className='navbar is-fixed-top'
            id='navbarBg'
            role='navigation'
            aria-label='main-navigation'
            style={{background: this.state.background}}
          >

            <div className='navbar-brand'>

              <Link to='/' className='is-paddingless' title='Logo'>
                <div style={{ paddingTop: '.5rem', paddingLeft: '.5rem' }}>
                  <StaticImage
                    src='../../img/click-collect.png'
                    alt='logo'
                    placeholder='blurred'
                    layout='fixed'
                    width={35}
                    loading='eager'
                  />
                </div>
              </Link>


              <div style={{ marginLeft: 'auto' }}>
              {this.state.showButton ?
                <Bounce>
                  <Link
                    to='/#starting'
                    className='button is-primary is-outlined'
                    style={{ width: '200px', display: 'block', marginTop: '10px', marginLeft: 'auto', marginRight: '1.5rem' }}
                  >
                    <FormattedMessage id='startNowBtnTxt' />
                  </Link>
                </Bounce>
                : null }
              </div>
              {/* Hamburger menu */}
              {/*
              <div className='navbar-burger burger' data-target='navMenu' style={{ marginLeft: '0px' }}>
              */}
              {/*<div className={`navbar-burger burger ${this.state.navBarActiveClass}`} data-target='navMenu' onClick={() => this.toggleHamburger()}>
                <span />
                <span />
                <span />
              </div>*/}
            </div>
            {/*<SearchIcon />
            <WishlistIcon />
            <CartIcon />*/}


              {data.menuItems.length > 0 && (
                <div id='navMenu' className={`navbar-menu ${this.state.navBarActiveClass}`}>
                  <div className='navbar-start has-text-centered' onClick={() => this.toggleHamburger()}>
                    {data.menuItems.map(menuItem => (
                      <CustomLink
                        key={menuItem.linkURL}
                        linkType={menuItem.linkType}
                        linkURL={menuItem.linkURL}
                        className="navbar-item is-uppercase"
                      >
                        {menuItem.label}
                      </CustomLink>
                    ))}
                  </div>
                </div>
              )}

            </nav>

          </div>

        </>
    )
  }
}

const Navbar = props => {
  if (!props.data) {
    return null;
  }
  const data = props.data.edges[0].node.frontmatter;
  return <NavbarTemplate data={data} />;
};

export { Navbar };

const Banner = styled.div`
  position: relative;
  width: 100%;
  // background: -moz-linear-gradient(90deg, #a10000 0%, rgba(188, 0, 0, 0.5) 100%);
  // background: -webkit-linear-gradient(90deg, #a10000 0%, rgba(188, 0, 0, 0.5) 100%);
  // background: linear-gradient(90deg, #a10000 0%, rgba(188, 0, 0, 0.5) 100%);
  background: #000;
  padding: 5px;
  @media(max-width: 380px){
    padding-left: 30px;
  }
  font-size: 0.7rem;
  color: #fff;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
`
