import React, { useState, useEffect, useContext, useRef } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
// import logo from '../../../img/click-collect.png'
import { NavbarBottom } from './NavbarBottomStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHamburger } from '@fortawesome/free-solid-svg-icons/faHamburger'
import { faWineBottle } from '@fortawesome/free-solid-svg-icons/faWineBottle'
import { faTshirt } from '@fortawesome/free-solid-svg-icons/faTshirt'
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook'

import { CartItemsContext } from '../../../context/CartItemsContext'

export default (props) => {
  const [hideOnScroll, setHideOnScroll] = useState(true)
  // const [cartItems, setCartItems] = useState([])
  const [cartItems, setCartItems] = useContext(CartItemsContext)
  // const cartItems = useContext(CartItemsContext)

  // console.log(cartItems.length)
  // -> null

  useEffect(() => {
    // if (typeof window !== 'undefined') {
    //   // setCartItems(localStorage.getItem("click&collect-cart"))
    //
    //
    //   if(localStorage.getItem("click&collect-cart") !== null) {
    //     let localCart = localStorage.getItem("click&collect-cart");
    //     setCartItems(JSON.parse(localCart))
    //   }else{
    //     localStorage.setItem("click&collect-cart", JSON.stringify(cartItems))
    //   }
    // }

    if (typeof window !== 'undefined') {
      renderNavbarIcon()
    }
    setHideOnScroll(false)
  }, [props])


  function renderNavbarIcon () {
    if (typeof window !== 'undefined') {
      return (

        <StyledLink to={cartItems.length !== 0 ? '/bestellung' : '/'} style={{ position: 'relative', display: 'inline-block', width: '20%', height: '100%', background: 'transparent' }} className='has-text-centered'>

          <div style={{ maxHeight: '4.5rem', maxWidth: '4.5rem', position: 'absolute', bottom: '10px', left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto' }}>
            <StaticImage
              src='../../../img/click-collect.png'
              alt='Logo'
              placeholder='blurred'
              layout='constrained'
            />
          </div>

          {cartItems.length !== 0 ? <span style={{ background: 'red', position: 'absolute', zIndex: '200', left: '40px', right: '0', bottom: '55px', width: '20px', height: '20px', color: '#fff', borderRadius: '9999px', marginLeft: 'auto', marginRight: 'auto' }}>{cartItems.length}</span> : null}

        </StyledLink>
      )
    }
  }

  function usePrevious (value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )

  return (

    <>
      <NavbarBottom show={cartItems !== usePrevious(cartItems) ? true : hideOnScroll}>

        <NavbarBottomContent
          role='navigation'
          aria-label='bottom-navigation' style={{ boxShadow: '0 0 4px black', background: '#2A2A2A' }}
        >
          <Link to='/products/food'
            activeStyle={{ color: '#DF8136' }}
            partiallyActive={true}
            style={{ display: 'inline-block', width: '20%', color: '#fff' }}
            className='navbar-item is-expanded has-text-centered'
          >
            <FontAwesomeIcon icon={faHamburger} className='navIcon' style={{ fontSize: '1rem' }} />
            <p className='is-size-7'>Food</p>
          </Link>

          <Link to='/products/books'
            activeStyle={{ color: '#DF8136' }}
            partiallyActive={true}
            style={{ display: 'inline-block', width: '20%', color: '#fff' }}
            className='navbar-item is-expanded has-text-centered'
          >
            <FontAwesomeIcon icon={faBook} className='navIcon' style={{ fontSize: '1rem' }} />
            <p className='is-size-7'>Books</p>
          </Link>

          {renderNavbarIcon()}

          <Link
            to='/products/wine'
            activeStyle={{ color: '#DF8136' }}
            partiallyActive={true}
            className='navbar-item is-expanded has-text-centered'
            style={{ display: 'inline-block', width: '20%', color: '#fff' }}
          >
            <FontAwesomeIcon icon={faWineBottle} className='navIcon' style={{ fontSize: '1rem' }} />
            <p className='is-size-7'>Wine</p>
          </Link>

          <Link
            to='/products/cloth'
            activeStyle={{ color: '#DF8136' }}
            partiallyActive={true}
            className='navbar-item is-expanded has-text-centered'
            style={{ display: 'inline-block', width: '20%', color: '#fff' }}
          >
            <FontAwesomeIcon icon={faTshirt} className='navIcon' style={{ fontSize: '1rem' }} />
            <p className='is-size-7'>Cloth</p>
          </Link>
        </NavbarBottomContent>
      </NavbarBottom>
    </>
  )
}

const NavbarBottomContent = styled.div`
  background: #000;
  padding-bottom: .5rem;
  // padding-top: .5rem;
  // height: 50px;
`

const StyledLink = styled(Link)`
  a{
    &:hover{
      background: transparent !important;
    }
  }
`
