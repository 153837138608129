import styled from 'styled-components'

const NavbarBase = styled.nav`
  position: fixed;
  bottom: 20vh;
  right: 10px;
  z-index: 1030;
`

export const Navbar = styled(NavbarBase)`
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  transition: all 200ms ${props => (props.show ? 'ease-in' : 'ease-out')};
  transform: ${props => (props.show ? 'none' : 'translate(0, -100%)')};
`

export const Sidebar = styled(Navbar)`
  background: transparent;
  /* a {
    margin-right: 15px;
  } */
  writing-mode: vertical-lr;
  width: 40px;
  height: 10%;
  margin-right: 10px;
  transform: ${props => (props.show ? 'none' : 'translateX(100%)')};
`
