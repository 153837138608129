import React, { useState } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
// import { Link } from 'gatsby'
import chatIcon from '../../../../img/chatbot.svg'
import { Sidebar } from './NavbarSideStyles'
// import Contact from '../../../kontakt'
import styled from 'styled-components'
import ContactModal from '../../../ContactModal'
// import useOutsideClick from '../../../hooks/useOutsideClick'

export default (props) => {
  const [hideOnScroll, setHideOnScroll] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)

  function openContactModal (e) {
    // setShowContactModal(!showContactModal)
    setShowContactModal(true)
  }

  function handleCloseContactModal (e) {
    setShowContactModal(false)
  }

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll],
  )

  // function handleContactPanel() {
  //   setShowContactPanel(!showContactPanel)
  // }

  // useOutsideClick(ref, () => {
  //   setShowContactPanel(false)
  // })

  return (
    <>
      <Sidebar show={hideOnScroll}>
        <div
          role='navigation'
          aria-label='bottom-navigation'
        >
          <span onClick={openContactModal}>

            <img src={chatIcon} alt='Logo' style={{ width: '3rem', height: '3rem', cursor: 'pointer' }} />

          </span>
        </div>
      </Sidebar>


      <ContactModal
        // handleContactModal={handleContactModal}
        showContactModal={showContactModal}
        handleCloseContactModal={handleCloseContactModal}
      />

    </>
  )
}

const Panel = styled.div`
  position: fixed;
  top: 20%;
  height: auto;
  left: 0;
  right: 0;
  padding: 20px;
  // display: block;
`

const IconWrapper = styled.div`
  position: relative;
  // display: inline-block;
`

const Content = styled.div`
  height: 400px;
  overflow-y: scroll;
  padding: 10px;
  overflow-x: hidden;
`

const Icon = styled.div`
  position: absolute;
  top: 50px;
  left: auto;
  &:hover{
    cursor: pointer;
  }
`
