// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bestellung-js": () => import("./../../../src/pages/bestellung.js" /* webpackChunkName: "component---src-pages-bestellung-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-books-index-js": () => import("./../../../src/pages/products/books/index.js" /* webpackChunkName: "component---src-pages-products-books-index-js" */),
  "component---src-pages-products-cloth-index-js": () => import("./../../../src/pages/products/cloth/index.js" /* webpackChunkName: "component---src-pages-products-cloth-index-js" */),
  "component---src-pages-products-food-index-js": () => import("./../../../src/pages/products/food/index.js" /* webpackChunkName: "component---src-pages-products-food-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-shoes-index-js": () => import("./../../../src/pages/products/shoes/index.js" /* webpackChunkName: "component---src-pages-products-shoes-index-js" */),
  "component---src-pages-products-toys-index-js": () => import("./../../../src/pages/products/toys/index.js" /* webpackChunkName: "component---src-pages-products-toys-index-js" */),
  "component---src-pages-products-wine-index-js": () => import("./../../../src/pages/products/wine/index.js" /* webpackChunkName: "component---src-pages-products-wine-index-js" */),
  "component---src-templates-category-01-post-js": () => import("./../../../src/templates/category01-post.js" /* webpackChunkName: "component---src-templates-category-01-post-js" */),
  "component---src-templates-category-03-post-js": () => import("./../../../src/templates/category03-post.js" /* webpackChunkName: "component---src-templates-category-03-post-js" */),
  "component---src-templates-category-04-post-js": () => import("./../../../src/templates/category04-post.js" /* webpackChunkName: "component---src-templates-category-04-post-js" */),
  "component---src-templates-category-05-post-js": () => import("./../../../src/templates/category05-post.js" /* webpackChunkName: "component---src-templates-category-05-post-js" */),
  "component---src-templates-category-07-post-js": () => import("./../../../src/templates/category07-post.js" /* webpackChunkName: "component---src-templates-category-07-post-js" */),
  "component---src-templates-category-08-post-js": () => import("./../../../src/templates/category08-post.js" /* webpackChunkName: "component---src-templates-category-08-post-js" */),
  "component---src-templates-event-post-js": () => import("./../../../src/templates/event-post.js" /* webpackChunkName: "component---src-templates-event-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

