export default {
    // this enables the color modes feature
    // and is used as the name for the top-level colors object
    initialColorMode: '',
    // optionally enable custom properties
    // to help avoid a flash of colors on page load
    useCustomProperties: true,
    colors: {
        // these are the default light colors
        text: '#000',
        background: '#fff',
        // primary: '#0057B8',
        primary: 'yellow',
        accent: '#FFCD00',
        h1: {
          color: "red",
          fontSize: 6,
          fontWeight: "bold",
          lineHeight: "heading",
          fontFamily: "heading",
        },
        modes: {
            // this is the dark color mode colors
            dark: {
                text: '#fff',
                background: '#000',
                // primary: '#FFCD00',
                primary: 'blue',
                h1: {
                  color: "green",
                  fontSize: 6,
                  fontWeight: "bold",
                  lineHeight: "heading",
                  fontFamily: "heading",
                },
            },
        },
    },
};
